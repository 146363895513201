import React, { createContext, useEffect } from 'react';

import { navigate } from 'gatsby';

import { useAuth } from './firebase-auth-provider';
import { useFunnelRouting } from './funnel-routing-provider';

import LoadingScreen from '../components/shared/loading-screen';

export const ProtectedRouteContext = createContext(null);

/**
 *
 * This provider controls access to protected parts of the site.
 *
 * It checks for the presence of an authenticated firebase session.
 *
 * It delays taking any action if an authentication attempt is in progress, and thus should
 * be used further down in the tree -- beneath the <CustomTokenProvider />, for example, in
 * order to allow those providers the chance to authenticate a user.
 *
 * A couple points re: the use for `null` and `undefined`:
 *
 * - A value of `null` means the session is not authenticated
 * - A value of `undefined` means we do not yet know if the session is authenticated or not.
 *
 */
export const ProtectedRouteProvider = ({ children }) => {
  const auth = useAuth();
  const routing = useFunnelRouting();
  const { user, synchronized } = auth;

  useEffect(() => {
    auth.actions.prefetch();
  }, []);

  // Firebase auth is not synced, so prevent children from rendering
  if (!synchronized) {
    return <LoadingScreen text="Please wait while we sign you in..." />;
  }

  // If user resolves to null, the session is unauthenticated -- force user to sign in.
  if (user === null) {
    const route = routing.get('unauthorized');
    if (route) {
      navigate(`${route.url}`);
    } else {
      navigate(`/`);
    }
    return <LoadingScreen text="Redirecting..." />;
  }

  return children;
};

ProtectedRouteProvider.propTypes = {};

ProtectedRouteProvider.defaultProps = {};
